@import './responsive-variables.scss';

/*
Here we are generating grid template columns based on the responsive breakpoints
defined in the variables, we can extend the values in the variables to have other breakpoints in case we need to
to use the breakpoints classes I followed the same behavior in tailwind css, we add the name of the breakpoint followed by
the number of columns we need in each breakpoint, so for example we need number of columns to be 3 in xl screens and 2 otherwise
we add xl:grid-cols-3 grid-cols-2 and so on
*/

.grid{
    display: grid !important;
}

@for $i from 1 through 12 {
    .grid-cols-#{$i} {
      grid-template-columns: repeat(#{$i}, minmax(0, 1fr)) !important;
    }

    .col-span-#{$i} {
        grid-column: span #{$i} / span #{$i} !important;
    }
}

@each $name, $value in $breakpoints {
    @media (min-width: #{$value}) {
        @for $i from 1 through 12 {
            .#{$name}\:grid-cols-#{$i} {
              grid-template-columns: repeat(#{$i}, minmax(0, 1fr)) !important;
            }

            .#{$name}\:col-span-#{$i} {
                grid-column: span #{$i} / span #{$i} !important;
            }
        }
    }
}