.mmb-display-flex{
    display: flex;
}
.mmb-display-none {
    display: none;
}  
.mmb-display-inline-block {
    display: inline-block;
}  
.mmb-display-inherit {
    display: inherit !important;
}  
.mmb-display-block{
    display: block;
}
.mmb-display-unset {
    display: unset;
}

.mmb-display-column {
    flex-direction: column !important;
}

.mmb-visibility--hidden {
    visibility: hidden;
}

// Utils that are applicable for adjusting different display items
.mmb-justify-content-between{
    justify-content: space-between;
}
.mmb-justify-content-right{
    justify-content: flex-end;
}
.mmb-justify-content-center{
    justify-content: center;
}
.mmb-align-items-center{
    align-items: center;
}
.mmb-align-self-center {
    align-self: center;
}
.mmb-flex-wrap{
    flex-wrap: wrap;
}
/*
TODO 
Need to check with @varun. 
This overflow-hidden is use on onboard page Step 1 
*/
.mmb-overflow-hidden{
    overflow: hidden;
}

.p-absolute {
    position: absolute;
}

.p-relative {
    position: relative;
}

.d-none {
    display: none !important;
}