$types: (
    "co-founder": $co-founder,
    "connection": $connection,
    "expert-advice": $expert-advice,
    "investment": $investment,
    "mentor": $mentor,
    "partnership": $partnership,
    "volunteer": $volunteer,
    "other": $other
);

@mixin new-opportunity-card-hover($color) {
    outline: 2px solid $color;
}

@each $type,
$color in $types {
    .#{$type} {
        background-color: #{$color};
    }

    [data-type="#{$type}"].new-opportunity-type-card {

        .new-category-icon {
            background-color: #{$color} !important;
        }

        .selected-icon {
            color: #{$color} !important;
        }

        &.new-opportunity-card--active {
            outline: 2px solid $color;
        }

        &:hover {
            @include new-opportunity-card-hover(#{$color});
        }
    }

}