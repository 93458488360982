@import './responsive-variables.scss';

@for $i from 0 through 100 {
    .w-#{$i}\% {
        width: #{$i}#{"%"} !important;
    }

    .minw-#{$i}\% {
        min-width: #{$i}#{"%"}!important;
    }

    .maxw-#{$i}\% {
        max-width: #{$i}#{"%"} !important;
    }

    $i: $i + 10;
}


@each $name, $value in $breakpoints {
    @media (min-width: #{$value}) {
        @for $i from 0 through 1200 {
            .#{$name}\:w-#{$i}px {
                width: #{$i}px !important;
            }
        
            .#{$name}\:minw-#{$i}px {
                min-width: #{$i}px !important;
            }
        
            .#{$name}\:maxw-#{$i}px {
                max-width: #{$i}px !important;
            }

            $i: $i + 25;
        }

        @for $i from 0 through 100 {
            .#{$name}\:w-#{$i}\% {
                width: #{$i}#{"%"} !important;
            }
        
            .#{$name}\:minw-#{$i}\% {
                min-width: #{$i}#{"%"}!important;
            }
        
            .#{$name}\:maxw-#{$i}\% {
                max-width: #{$i}#{"%"} !important;
            }

            $i: $i + 10;
        }
    }
}