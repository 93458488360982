.mmb-article-render {
    -webkit-mask-image: linear-gradient(180deg, #000 85%, transparent);
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;

    table {
        width: 100%;
    }

    img.mcnImage {
        width: 100%;
    }

    h3 {
        @extend .mmb-subheading-2;
    }

    p {
        margin-top: $spacer * 4;
    }
}