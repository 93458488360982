.quote-left {
    top: 0;
    left: 0;
    margin-top: $spacer * 4;
    font-family: $font-family-variant;
    font-size: $spacer * 16 !important;
    margin-left: $spacer * 3;
}

.quote-right {
    bottom: 0;
    right: 0;
    //margin-bottom: $spacer * 7;
    font-family: $font-family-variant;
    font-size: $spacer * 16 !important;
}