.newsroom-block {
  .search-input {
    .icon {
      transform: translate(0, -50%);
    }
    input::placeholder{
      color: $page-text-white;
    }
  }
}
.banner-block {
  .nav-tabs {
    .nav-item {
      a.active{
        border-radius: 2px;
        color: $secondary-300;
        border-bottom: 3px solid $secondary-300 !important;
      }
      a:hover {
        border-radius: 2px;
        color: $secondary-300;
        border-bottom: 3px solid $secondary-300 !important;
      }
    }
  }
  .banner-tab {
      .banner-tab-inner {
        // background-image: url(/assets/images/group-12.jpg);
      }
  }
}
.news-title {
  .viw-all:hover{
    background-color: $secondary-300 !important;
    color: $page-text-white !important;
  }
}
.pulse-benner-block {
  // background-image: url(/assets/images/group-12.jpg);
 .pulse-benner-button {
        button.read:hover{
          color: $page-text-white !important;
          background-color: transparent !important;
        }
        button.see:hover{
          color: $text-black !important;
          background-color: $white-bg-fff !important;
        }
  }
}
