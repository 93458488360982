.backdrop-filter-xs{
    backdrop-filter: blur(90px);
}

.backdrop-filter-sm{
    backdrop-filter: blur(60px);
}

.backdrop-filter-md{
    backdrop-filter: blur(30px);
}

.backdrop-filter-lg{
    backdrop-filter: blur(15px);
}

.grayscale-filter{
    filter: grayscale(100%);
}
  