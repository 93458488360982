@import './responsive-variables';

/*
In this section I extended the already implemented utilities class for paddings, with the same behavior but I just added the responsive
prefix in case we need them responsive (same spacer range start and end)
*/

@each $name, $value in $breakpoints {
    @media (min-width: #{$value}) {
        @for $i from $spacer-range-start through $spacer-range-end {
            .#{$name}\:p-#{$i} {
                margin: $spacer * $i !important;
            }
            
            .#{$name}\:pl-#{$i} {
                margin-left: $spacer * $i !important;
            }
        
            .#{$name}\:pr-#{$i} {
                margin-right: $spacer * $i !important;;
            }
        
            .#{$name}\:px-#{$i} {
                margin-left: $spacer * $i !important;
                margin-right: $spacer * $i !important;
            }
            
            .#{$name}\:pt-#{$i} {
                margin-top: $spacer * $i !important;
            }
        
            .#{$name}\:pt-n#{$i} {
                margin-top: -$spacer * $i;
            }
        
            .#{$name}\:pb-#{$i} {
                margin-bottom: $spacer * $i !important;
            }
        
            .#{$name}\:py-#{$i} {
                margin-top: $spacer * $i;
                margin-bottom: $spacer * $i;
            }
        
            .#{$name}\:pt-n#{$i} {
                margin-top: - $spacer * $i !important;
            }
        
            .#{$name}\:pl-n#{$i} {
                margin-left: - $spacer * $i !important;
            }
        }
    }
}