.avatar-xs,
.mmb-avatar-xs {
    height: $spacer * 6;
    width: $spacer * 6;
}

.avatar-sm,
.mmb-avatar-sm {
    height: $spacer * 12;
    width: $spacer * 12;
}

.avatar-md,
.mmb-avatar-md {
    height: $spacer * 16;
    width: $spacer * 16;
}

.avatar-lg,
.mmb-avatar-lg {
    height: $spacer * 20;
    width: $spacer * 20;
}
.mmb-listing-card-avatar-sm {
    @extend .box-shadow-sm;
    width: $spacer * 25;
    height: $spacer * 12;
    top: -$spacer * 6;
    left: $spacer * 5;
    display: flex;
    border-radius: $spacer * 0.75;
    img {
        border-radius: $spacer * 0.75;
    }
}
.mmb-listing-card-avatar {
    width: $spacer * 16;
    height: $spacer * 16;
    top: -$spacer * 9;
    left: $spacer * 5;
    border: none;
    border-radius: $spacer * 0.75;
    background-color: $white;
    box-shadow: 0 4px 8px 0 rgba(185, 190, 202, 0.35);
  
    .avatar-sm {
        width: $spacer * 16;
        height: $spacer * 16;
    }
  }
