  .top-banner-1 {
    background-image: url('./../../../projects/mmb-web-app/src/assets/images/home/mmb-hero-image-1.webp');
  }

  .top-banner-2 {
    background-image: url('./../../../projects/mmb-web-app/src/assets/images/home/mmb-hero-image-2.webp');
  }

  .top-banner-3 {
    background-image: url('./../../../projects/mmb-web-app/src/assets/images/home/mmb-hero-image-3.webp');
  }

  .top-banner-4 {
    background-image: url('./../../../projects/mmb-web-app/src/assets/images/home/mmb-hero-image-4.webp');
  }

  .top-banner-5 {
    background-image: url('./../../../projects/mmb-web-app/src/assets/images/home/mmb-hero-image-5.webp');
  }

  .top-banner-old {
    background-image: linear-gradient(to bottom, rgba(57, 53, 109, 0.55), rgba(57, 53, 109, 0.55)),
      url('./../../../projects/mmb-web-app/src/assets/images/hero-home.jpg');

  }

  .network-you-need {
    background-image: url('./../../../projects/mmb-web-app/src/assets/images/network-desktop@1x.png');
    background-size: cover;
    border-radius: $spacer * 3;
    background-position: center;
  }

  .network-you-need--sm {
    background-image: url('./../../../projects/mmb-web-app/src/assets/images/network-mobile@1x.png');
    background-size: cover;
    border-radius: $spacer * 3;
    background-position: center;
  }

  .build-future--mobile {
    background-image: url('./../../../projects/mmb-web-app/src/assets/images/back2-mobile.png');
  }

  .digital-community {
    background-image: url('./../../../projects/mmb-web-app/src/assets/images/community.jpg');
  }

  .journey {
    .main-content {
      background-image: url('./../../../projects/mmb-web-app/src/assets/images/meet-people.jpg');
    }
  }

  .high-impact-oppotunity {
    .home-opportunity-card {
      animation: slide-top ease 1s;
      animation-fill-mode: forwards;
    }

    .scroll-button {
      &:hover {
        background-color: $black;

        i {
          color: $white;
        }
      }
    }
  }

  .with-community {
    .wrapper {
      .list-cards {
        .list-card {
          &.active {
            background-color: $light-blue-100;
            @extend .border-radius-sm;

            .mmb-title {
              color: $buttons-primary;
              position: relative;
              padding-left: $spacer * 4;

              &::before {
                @include bluetriangle;
              }
            }

            .mmb-subheading-2 {
              padding-left: $spacer * 4;
            }
          }
        }
      }
    }
  }

  .organisation {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(17, 17, 17, 0.75)),
      url('./../../../projects/mmb-web-app/src/assets/images/home/org-bg.webp');

    .item-lists {
      li {
        position: relative;

        &::before {
          @include whitetriangle;
        }
      }

    }

  }

  .organisation-old {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(17, 17, 17, 0.75)),
      url('./../../../projects/mmb-web-app/src/assets/images/organisation.jpg');

    .item-lists {
      li {
        position: relative;

        &::before {
          @include whitetriangle;
        }
      }

    }

  }

  .testimonials {
    .testimoinal-slider {
      .slick-list {
        height: 100%;
      }

      .slick-track {
        display: flex;

        .item-wrap {
          min-height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      .slick-slide {
        height: inherit;
      }

      .slick-dots {
        bottom: 0;
        text-align: center;
        position: inherit;
        margin-top: 60px;

        li {
          margin: 0;

          button {
            &::before {
              content: '';
              background-color: $white-bg-fff;
              width: $spacer * 3;
              height: $spacer * 3;
              border-radius: 50%;
              border: $spacer * 0.25 solid $text-black;
            }
          }

          &.slick-active {
            button {
              &::before {
                background-color: $buttons-primary;
                border: $spacer * 0.25 solid $buttons-primary;
              }
            }
          }
        }
      }
    }
  }

  .newsletter {
    background-image: url('./../../../projects/mmb-web-app/src/assets/images/newsletter.jpg');

    .newsletter-form {
      input {
        color: $page-text-white;
        background-color: transparent;
        border: 1px solid $page-text-white;
        box-shadow: none;
        outline: none;
        max-width: $spacer * 100;
        width: 100%;

        &::placeholder {
          color: $page-text-white;
        }
      }
    }
  }

  .statistic-box {
    opacity: 90%;
    box-shadow: 0px 4px 48px 0px #00000014;
  }

  .slider-content {
    padding-top: 40px;

    .slick-dots {
      top: 0;

      li.slick-active button::before {
        background-color: $text-black !important;
        border: 1px solid $text-black !important;
      }
    }
  }

  .testimonial-slider-mobile {
    .slick-dots {
      top: 0;
      text-align: right;

      li button::before {
        content: '' !important;
        border: 1px solid $text-black !important;
        border-radius: 10px;
      }

      li.slick-active button::before {
        content: '' !important;
        background-color: $text-black !important;
        border: 1px solid $text-black !important;
        border-radius: 10px;
      }
    }
  }

  .testimonial-slider-mobile-position-mobile {
    .slick-dots {
      margin-top: 20px;

      li button::before {
        content: '' !important;
        border: 1px solid $text-black !important;
        border-radius: 10px;
      }

      li.slick-active button::before {
        content: '' !important;
        background-color: $text-black !important;
        border: 1px solid $text-black !important;
        border-radius: 10px;
      }
    }
  }

  .roadmap-tile-block {
    z-index: 1;

    &:hover {
      transform: scale(1.1);
      transition: transform 0.3s ease;
      z-index: 100;

      .overlay {
        opacity: 1;
        visibility: visible;
      }
    }

    .overlay {
      width: 100%;
      min-height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @extend .bg-white;
      @extend .border-radius-sm;
      visibility: hidden;
      opacity: 0;
      z-index: 1000;
    }
  }

  .testimonial-slider-oportunity {
    .slick-dots {
      text-align: left;

      li button::before {
        content: '' !important;
        border: 1px solid $text-black !important;
        border-radius: 10px;
      }

      li.slick-active button::before {
        content: '' !important;
        background-color: $text-black !important;
        border: 1px solid $text-black !important;
        border-radius: 10px;
      }
    }
  }

  .section-movemebackslider {

    .item-wrap {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .slick-list {
      padding-left: 0 !important;
    }

    .item-wrap {
      &:hover {
        .card-content {
          max-height: 1000px;
          transition: max-height 0.5s ease-in;
          @extend .pb-4, .card-text-overlay
        }
      }

      .card-content {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.5s ease-out;
      }
    }
  }


  .section-slider {

    .slider-content,
    .slider-content-journey {
      .slick-dots {
        li {
          margin: 0;

          button {
            &::before {
              content: '';
              background-color: white;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              border: solid 0.8px #000000;
            }
          }

          &.slick-active {
            button {
              &::before {
                background-color: $buttons-primary;
                border: solid 0.8px $buttons-primary;
              }
            }
          }
        }
      }

      @include at-query($max, $xlarge) {
        padding-left: 0;
      }
    }
  }

  .journey {
    .section-slider {
      .journey-slider {
        max-width: 1000px;
        width: 100%;
        margin: 0 auto;

        .fp {
          width: $spacer * 7;
          height: 20px;
          background-size: cover;
        }

        ul.slick-dots {
          position: absolute;
          display: inline-block;
          vertical-align: middle;
          width: auto;
          bottom: 50px;
          left: 50.5%;

          @include at-query($max, $xlarge) {
            bottom: 10px;

          }

          @include at-query($max, $medium) {
            bottom: -30px;
          }


          li {
            button::before {
              width: 12px;
              opacity: 1;
              height: 12px;
              background-color: transparent;
              border: solid 0.8px $white-bg-fff;
            }
          }

          li.slick-active {
            button::before {
              background-color: $white-bg-fff;
              border: solid 0.8px $white-bg-fff;
            }
          }
        }
      }
    }
  }

  .unique-section-box-shadow {
    box-shadow: 0px 24px 48px 0px #0000000F;
  }

  .testimonials-radius {
    border-top-right-radius: $spacer * 3;
    border-bottom-left-radius: $spacer * 3;
  }