ul {
    padding: 0 $spacer * 4;
}

.list-style--square {
    list-style: square;
}

.list-style--none {
    list-style: none;
}

.list-style-colored--light {
    ul {
        list-style: none;
        margin-left: $spacer * 3;

        li {
            margin-bottom: $spacer * 2;

            &::before {
                content: "";
                margin-top: 6px;
                margin-left: -20px;
                position: absolute;
                width: $spacer * 2;
                height: $spacer * 2;
                background-image: url('/assets/images/icons/bullet-icon.png');
            }
        }
    }
}

.list-item-selected {
    background: rgba(52, 152, 219, 0.2);
}

.list-style-none {
    list-style: none;
}