/**
Typography level is being set here.
Variables will go to _variables.scss but font-face etc will come in this file.
**/

/**
=====================
Typography Core layer
=====================
**/

$font-family-base: "Open Sans";
$font-family-base-light: "Open Sans Light";
$font-family-base-semi-bold: "Open Sans SemiBold";
$font-family-base-bold: "Open Sans Bold";

$font-family-variant: "Helvetica Now Display", sans-serif;
$font-family-variant-medium: "Helvetica Now Display Medium", sans-serif;
$font-family-variant-light: "Helvetica Now Display ExtLt", sans-serif;
$font-family-variant-bold: 'Helvetica Now Display Bold';

@font-face {
    font-family: 'Helvetica Now Display ExtLt';
    src: local('Helvetica Now Display ExtLt'), local('HelveticaNowDisplay-ExtLt'),

        url(./fonts/HelveticaNowDisplay-ExtLt.woff2) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display ExtBlk';
    src: local('Helvetica Now Display ExtBlk'), local('HelveticaNowDisplay-ExtBlk'),
        url(./fonts/HelveticaNowDisplay-ExtBlk.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display Bold';
    src: local('Helvetica Now Display Bold'), local('HelveticaNowDisplay-Bold'),
        url(./fonts/HelveticaNowDisplay-Bold.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display Regular';
    src: local('Helvetica Now Display Regular'), local('HelveticaNowDisplay-Regular'),
        url(./fonts/HelveticaNowDisplay-Regular.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display Light';
    src: local('Helvetica Now Display Thin'), local('HelveticaNowDisplay-Thin'),
        url(./fonts/HelveticaNowDisplay-Thin.woff2) format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display ExtBd';
    src: local('Helvetica Now Display ExtBd'), local('HelveticaNowDisplay-ExtraBold'),
        url(./fonts/HelveticaNowDisplay-ExtraBold.woff2) format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display Medium';
    src: local('Helvetica Now Display Medium'), local('HelveticaNowDisplay-Medium'),
        url(./fonts/HelveticaNowDisplay-Medium.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display Black';
    src: local('Helvetica Now Display Black'), local('HelveticaNowDisplay-Black'),
        url(./fonts/HelveticaNowDisplay-Black.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now Display Hairline';
    src: local('Helvetica Now Display Hairline'), local('HelveticaNowDisplay-Hairline'),
        url(./fonts/HelveticaNowDisplay-Hairline.woff2) format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), local('OpenSans'),
        url(./fonts/OpenSans.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Bold';
    src: local('Open Sans Bold'), local('OpenSansBold'),
        url(./fonts/opensans-bold.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans SemiBold';
    src: local('Open Sans SemiBold'), local('OpenSansSemiBold'),
        url(./fonts/opensans-bold.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Light';
    src: local('Open Sans Light'), local('OpenSansLight'),
        url(./fonts/opensans-light.ttf) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

// $font-types--xl: (
//     "display-3": (
//         font-family:  $font-family-variant-bold, font-size: 32px, line-height:1.25, letter-spacing:normal),
//     "display-2": (
//         font-family:  $font-family-variant-bold, font-size: 44px, line-height:1.27, letter-spacing:normal),
//     "headline": (
//         font-family:  $font-family-variant-bold, font-size: 44px, line-height:1.27, letter-spacing:normal), // display-2 = headline
//     "display-1": (
//         font-family:  $font-family-variant-bold, font-size: 64px, line-height:1.0625, letter-spacing:normal),
//     "title": (
//         font-family:  $font-family-variant-bold, font-size: 44px, line-height:1.27, letter-spacing:normal),
//     "subheading-1": (
//         font-family:  $font-family-base-bold, font-size: 26px, font-weight:300, line-height:1.3, letter-spacing:normal),
//     "subheading-2": (
//         font-family:  $font-family-base-light, font-size: 26px, font-weight:600, line-height:1.3, letter-spacing:normal),
//     "subheading-3": (
//         font-family:  $font-family-base-bold, font-size: 26px, line-height:1.35, letter-spacing:normal),
//     "body-1": (
//         font-family:  $font-family-base, font-size: 24px, font-weight:normal, line-height:1.45, letter-spacing:normal),
//     "body-2": (
//         font-family:  $font-family-base-bold, font-size: 24px, font-weight: bold, line-height:1.45, letter-spacing:normal),
//     "caption": (
//         font-family:  $font-family-base, font-size: 22px, font-weight:normal, line-height:1.5, letter-spacing:normal),
//     "caption-2": (
//         font-family:  $font-family-base, font-size: 18px, font-weight:normal, line-height:1.5, letter-spacing:normal),
//     "caption-3": (
//         font-family:  $font-family-base, font-size: 12px, font-weight:normal, line-height:1.5, letter-spacing:normal),
//     "button": (
//         font-family:  $font-family-base, font-size: 18px, font-weight:normal, line-height:1.44, letter-spacing:normal),
// );
$font-types--xl: (
    "display-3": (
        font-family:  $font-family-variant-bold, font-size: 32px, line-height:1.25, letter-spacing:normal),
    "display-2": (
        font-family:  $font-family-variant-bold, font-size: 44px, line-height:1.27, letter-spacing:normal),
    "headline": (
        font-family:  $font-family-variant-bold, font-size: 44px, line-height:1.27, letter-spacing:normal), // display-2 = headline
    "display-1": (
        font-family:  $font-family-variant-bold, font-size: 64px, line-height:1.0625, letter-spacing:normal),
    "title": (
        font-family:  $font-family-variant-bold, font-size: 44px, line-height:1.27, letter-spacing:normal),
    "subheading-1": (
        font-family:  $font-family-base-bold, font-size: 22px, font-weight:300, line-height:1.3, letter-spacing:normal),
    "subheading-2": (
        font-family:  $font-family-base-light, font-size: 22px, font-weight:600, line-height:1.3, letter-spacing:normal),
    "subheading-3": (
        font-family:  $font-family-base-bold, font-size: 22px, line-height:1.35, letter-spacing:normal),
    "body-1": (
        font-family:  $font-family-base, font-size: 18px, font-weight:normal, line-height:1.45, letter-spacing:normal),
    "body-2": (
        font-family:  $font-family-base-bold, font-size: 18px, font-weight: bold, line-height:1.45, letter-spacing:normal),
    "caption": (
        font-family:  $font-family-base, font-size: 16px, font-weight:normal, line-height:1.5, letter-spacing:normal),
    "caption-2": (
        font-family:  $font-family-base, font-size: 14px, font-weight:normal, line-height:1.5, letter-spacing:normal),
    "button": (
        font-family:  $font-family-base, font-size: 16px, font-weight:normal, line-height:1.44, letter-spacing:normal),
);

$font-types--lg: (
    "display-3": (
        font-family:  $font-family-variant-bold, font-size: 32px, line-height:1.25, letter-spacing:normal),
    "display-2": (
        font-family:  $font-family-variant-bold, font-size: 44px, line-height:1.27, letter-spacing:normal),
    "headline": (
        font-family:  $font-family-variant-bold, font-size: 44px, line-height:1.27, letter-spacing:normal), // display-2 = headline
    "display-1": (
        font-family:  $font-family-variant-bold, font-size: 64px, line-height:1.0625, letter-spacing:normal),
    "title": (
        font-family:  $font-family-variant-bold, font-size: 44px, line-height:1.27, letter-spacing:normal),
    "subheading-1": (
        font-family:  $font-family-base-bold, font-size: 22px, font-weight:300, line-height:1.3, letter-spacing:normal),
    "subheading-2": (
        font-family:  $font-family-base-light, font-size: 22px, font-weight:600, line-height:1.3, letter-spacing:normal),
    "subheading-3": (
        font-family:  $font-family-base-bold, font-size: 22px, line-height:1.35, letter-spacing:normal),
    "body-1": (
        font-family:  $font-family-base, font-size: 18px, font-weight:normal, line-height:1.45, letter-spacing:normal),
    "body-2": (
        font-family:  $font-family-base-bold, font-size: 18px, font-weight: bold, line-height:1.45, letter-spacing:normal),
    "caption": (
        font-family:  $font-family-base, font-size: 16px, font-weight:normal, line-height:1.5, letter-spacing:normal),
    "caption-2": (
        font-family:  $font-family-base, font-size: 14px, font-weight:normal, line-height:1.5, letter-spacing:normal),
    "button": (
        font-family:  $font-family-base, font-size: 16px, font-weight:normal, line-height:1.44, letter-spacing:normal),
);

// Default variation -- md
$font-types: (
    "display-3": (
        font-family:  $font-family-variant-bold, font-size: 28px, line-height:1.35, letter-spacing:normal),
    "display-2": (
        font-family:  $font-family-variant-bold, font-size: 40px, line-height:1.25, letter-spacing:normal),
    "headline": (
        font-family:  $font-family-variant-bold, font-size: 40px, line-height:1.25, letter-spacing:normal), // display-2 = headline
    "display-1": (
        font-family:  $font-family-variant-bold, font-size: 56px, line-height:1.0625, letter-spacing:normal),
    "title": (
        font-family:  $font-family-base-bold, font-size: 24px, line-height:1.25, letter-spacing:normal),
    "subheading-1": (
        font-family:  $font-family-base-bold, font-size: 20px, font-weight:300, line-height:1.3, letter-spacing:normal),
    "subheading-2": (
        font-family:  $font-family-base, font-size: 20px, font-weight:600, line-height:1.3, letter-spacing:normal),
    "subheading-3": (
        font-family:  $font-family-base-bold, font-size: 20px, line-height:1.35, letter-spacing:normal),
    "body-1": (
        font-family:  $font-family-base, font-size: 18px, font-weight:normal, line-height:1.45, letter-spacing:normal),
    "body-2": (
        font-family:  $font-family-base-bold, font-size: 18px, font-weight: bold, line-height:1.45, letter-spacing:normal),
    "caption": (
        font-family:  $font-family-base, font-size: 16px, font-weight:normal, line-height:1.5, letter-spacing:normal),
    "caption-2": (
        font-family:  $font-family-base, font-size: 14px, font-weight:normal, line-height:1.5, letter-spacing:normal),
    "caption-3": (
        font-family:  $font-family-base, font-size: 12px, font-weight:normal, line-height:1.5, letter-spacing:normal),
    "button": (
        font-family:  $font-family-base, font-size: 16px, font-weight:normal, line-height:1.44, letter-spacing:normal),
);

$font-types--sm: (
    "display-3": (
        font-family:  $font-family-variant, font-size: 24px, line-height:1.33, letter-spacing:normal),
    "display-2": (
        font-family:  $font-family-variant, font-size: 32px, line-height:1.25, letter-spacing:normal),
    "headline": (
        font-family:  $font-family-variant, font-size: 32px, line-height:1.25, letter-spacing:normal), // display-2 = headline
    "display-1": (
        font-family:  $font-family-variant, font-size: 48px, line-height:1.08, letter-spacing:normal),
    "title": (
        font-family:  $font-family-base-bold, font-size: 24px, line-height:1.25, letter-spacing:normal),
    "subheading-1": (
        font-family:  $font-family-base-bold, font-size: 20px, font-weight:300, line-height:1.3, letter-spacing:normal),
    "subheading-2": (
        font-family:  $font-family-base, font-size: 20px, font-weight:600, line-height:1.3, letter-spacing:normal),
    "subheading-3": (
        font-family:  $font-family-base-bold, font-size: 20px, line-height:1.33, letter-spacing:normal),
    "body-1": (
        font-family:  $font-family-base, font-size: 16px, font-weight:normal, line-height:1.44, letter-spacing:normal),
    "body-2": (
        font-family:  $font-family-base-bold, font-size: 16px, font-weight: bold, line-height:1.44, letter-spacing:normal),
    "caption": (
        font-family:  $font-family-base, font-size: 14px, font-weight:normal, line-height:1.6, letter-spacing:normal),
    "caption-2": (
        font-family:  $font-family-base, font-size: 12px, font-weight:normal, line-height:1.5, letter-spacing:normal),
    "caption-3": (
        font-family:  $font-family-base, font-size: 12px, font-weight:normal, line-height:1.5, letter-spacing:normal),
    "button": (
        font-family:  $font-family-base, font-size: 14px, font-weight:normal, line-height:1.44, letter-spacing:normal),
);

$font-types--xs: (
    "display-3": (
        font-family:  $font-family-variant, font-size: 18px, line-height:1.33, letter-spacing:normal),
    "display-2": (
        font-family:  $font-family-variant, font-size: 24px, line-height:1.25, letter-spacing:normal),
    "headline": (
        font-family:  $font-family-variant, font-size: 24px, line-height:1.25, letter-spacing:normal), // display-2 = headline
    "display-1": (
        font-family:  $font-family-variant, font-size: 40px, line-height:1.08, letter-spacing:normal),
    "title": (
        font-family:  $font-family-base-bold, font-size: 24px, line-height:1.25, letter-spacing:normal),
    "subheading-1": (
        font-family:  $font-family-base-bold, font-size: 16px, font-weight:300, line-height:1.3, letter-spacing:normal),
    "subheading-2": (
        font-family:  $font-family-base, font-size: 16px, font-weight:600, line-height:1.3, letter-spacing:normal),
    "subheading-3": (
        font-family:  $font-family-base-bold, font-size: 16px, line-height:1.33, letter-spacing:normal),
    "body-1": (
        font-family:  $font-family-base, font-size: 14px, font-weight:normal, line-height:1.44, letter-spacing:normal),
    "body-2": (
        font-family:  $font-family-base-bold, font-size: 14px, font-weight: bold, line-height:1.44, letter-spacing:normal),
    "caption": (
        font-family:  $font-family-base, font-size: 14px, font-weight:normal, line-height:1.6, letter-spacing:normal),
    "caption-2": (
        font-family:  $font-family-base, font-size: 12px, font-weight:normal, line-height:1.5, letter-spacing:normal),
    "caption-3": (
        font-family:  $font-family-base, font-size: 12px, font-weight:normal, line-height:1.5, letter-spacing:normal),
    "button": (
        font-family:  $font-family-base, font-size: 16px, font-weight:normal, line-height:1.44, letter-spacing:normal),
);


@mixin define-typography-level($font-family, $font-weight, $font-size, $line-height, $letter-spacing) {
    font-family: $font-family;
    font-weight: $font-weight;
    font-size: $font-size;
    line-height: $line-height;
    letter-spacing: $letter-spacing;
}

@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }

    @return $map;
}

@each $key, $value in $font-types {
    .mmb-#{$key} {
        @include define-typography-level(
            map-deep-get($font-types, #{$key}, "font-family"),
            map-deep-get($font-types, #{$key}, "font-weight"),
            map-deep-get($font-types, #{$key}, "font-size"),
            map-deep-get($font-types, #{$key}, "line-height"),
            map-deep-get($font-types, #{$key}, "letter-spacing")
        );
    }
}

@each $key, $value in $font-types--xl {
    .is-xl .mmb-#{$key} {
        @include define-typography-level(
            map-deep-get($font-types--xl, #{$key}, "font-family"),
            map-deep-get($font-types--xl, #{$key}, "font-weight"),
            map-deep-get($font-types--xl, #{$key}, "font-size"),
            map-deep-get($font-types--xl, #{$key}, "line-height"),
            map-deep-get($font-types--xl, #{$key}, "letter-spacing")
        );
    }
}

@each $key, $value in $font-types--lg {
    .is-lg .mmb-#{$key} {
        @include define-typography-level(
            map-deep-get($font-types--lg, #{$key}, "font-family"),
            map-deep-get($font-types--lg, #{$key}, "font-weight"),
            map-deep-get($font-types--lg, #{$key}, "font-size"),
            map-deep-get($font-types--lg, #{$key}, "line-height"),
            map-deep-get($font-types--lg, #{$key}, "letter-spacing")
        );
    }
}
@each $key, $value in $font-types--sm {
    .is-sm .mmb-#{$key} {
        @include define-typography-level(
            map-deep-get($font-types--sm, #{$key}, "font-family"),
            map-deep-get($font-types--sm, #{$key}, "font-weight"),
            map-deep-get($font-types--sm, #{$key}, "font-size"),
            map-deep-get($font-types--sm, #{$key}, "line-height"),
            map-deep-get($font-types--sm, #{$key}, "letter-spacing")
        );
    }
}

@each $key, $value in $font-types--xs {
    .is-xs .mmb-#{$key} {
        @include define-typography-level(
            map-deep-get($font-types--xs, #{$key}, "font-family"),
            map-deep-get($font-types--xs, #{$key}, "font-weight"),
            map-deep-get($font-types--xs, #{$key}, "font-size"),
            map-deep-get($font-types--xs, #{$key}, "line-height"),
            map-deep-get($font-types--xs, #{$key}, "letter-spacing")
        );
    }
}
