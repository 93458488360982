.mmb-input-group{
    background-color: transparent;
    width: 100%;
    outline: none;
    border: none;
    color: $white;
    border-radius: 4px;
    padding: $spacer * 4 $spacer * 4 $spacer * 4 $spacer * 14;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid $textbox-active-border
    }
    &:focus, &:active {
      background-color: $secondary;
      box-shadow: 0px 8px 8px 0px $textbox-active-shaddow;
      border: 1px solid $white;
    }
  }
 
  .mmb-input-group::placeholder {
    color: $white;
  }

  .input-group--disabled {
    input {
        pointer-events: none;
        background-color: $bg-gray-disabled !important;
        color: $text-gray-disabled !important;
    }
}

.mmb-input-group-autocomplete {
  border: 1px solid $other;
  color: $page-text-grey !important;
  @extend .mmb-display-flex, .p-2;
  i {
    color: $page-text-grey!important;
    @extend .px-1;
  }
  input {
    @extend .form-control, .w-100, .mmb-border-none, .pl-1;
    &::placeholder {
      color: $page-text-grey !important;
    }
  }

  &.mmb-input-group--disabled {
    pointer-events: none;
    background-color: $bg-gray-disabled;
    color: $text-gray-disabled;
    i {
      color: $text-gray-disabled;
    }
    input {
      pointer-events: none;
      background-color: $bg-gray-disabled;
      color: $text-gray-disabled;
      &::placeholder {
        color: $text-gray-disabled;
      }
    }
  }
}

.input-group-w-border {
  border: 1px solid $bg-gray-disabled;
  border-radius: 6px;

  .input-w-avatar {
      margin-top: 16px;
      margin-right: 16px;
  }
  input {
    &:focus{
      @extend .mmb-outline-none;
      @extend .box-shadow-none;
    }
  }
}
