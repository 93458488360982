.mmb-accordion-wrapper {
    &:hover {
        @extend .box-shadow-lg;
        .accordion-title {
            span {
                color: $secondary-300;
            }
        }
    }
    &.active{
        @extend .mmb-border-secondary;
        @extend .box-shadow-lg;
    }
}