.opacity-none  {
    opacity: 0;
}

.opacity-lightest {
    opacity: 0.35;
}

.opacity-lighter {
    opacity: 0.5;
}

.opacity-light {
    opacity: 0.75;
}

.opacity-fill {
    opacity: 1;
}

.opacity-1 {
  opacity: 1;
}