$inline-spacer: 4%;
$max-width-container--gt-md: 1440px; // container applicable for LG/XL screens
$gutter-lt-xl: $spacer * 10; // gutter size for MD/LG screens when viewport size < max-width class
$gutter-sm: $spacer * 5; // gutter size for SM screens when viewport size < MD screens
$gutter-xs: $spacer * 5; // gutter size for SM screens when viewport size < MD screens

$gutter-inline-md: $inline-spacer * 5; // gutter size for MD screens when viewport size < max-width class
$gutter-inline-sm: $inline-spacer * 3; // gutter size for SM screens when viewport size < MD screens
$gutter-inline-xs: $inline-spacer * 2; // gutter size for XS screens when viewport size < SM screens

.mmb-responsive-container {
    padding: 0 $gutter-lt-xl;
    margin: 0 auto;
    width: 100%;
    max-width: $max-width-container--gt-md;

    &.mmb-responsive-container--sm {
        padding: 0 $gutter-sm;
    }

    &.mmb-responsive-container--xs {
        padding: 0 $gutter-xs;
    }
}

.mmb-inline-container {
    display: inline-block;
    padding: 0 $gutter-inline-md;
    margin: 0 auto;
    width: 100%;
    max-width: $max-width-container--gt-md;
    overflow-y: auto;

    &.mmb-inline-container--xl {
        padding: 0 $gutter-inline-xs;
    }
    &.mmb-inline-container--sm {
        padding: 0 $gutter-inline-sm;
    }

    &.mmb-inline-container--xs {
        padding: 0 $gutter-inline-xs;
    }
}

.flex-basis-auto {
    flex-basis: 50% !important;
}

.flex-1 {
  flex: 1;
}
