.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
  }
  
 .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $toggle-background;
    -webkit-transition: .4s;
    transition: .4s;
    @extend .border-radius-md;
    &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        @extend .bg-white;
        -webkit-transition: .4s;
        transition: .4s;
      }
  }
  
  
  
  input:checked + .slider {
    background-color: $buttons-primary;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px $buttons-primary;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round:before {
    @extend .border-radius-50;
  }