// Font weight helpers
@for $weight from 1 through 9 {

    .font-weight-#{$weight * 100} {
        font-weight: #{$weight * 100} !important;
    }
}

.mmb-font-weight-900 {
    font-weight: 900;
}

.font-weight-normal {
    font-weight: normal !important;
}

.font-weight-light {
    // font-family: $font-family-variant-light !important;
    font-weight: 300;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-bold {
    font-family: $font-family-bold;
}

.font-weight-variant-bold {
    font-family: $font-family-variant-bold;
}

.font-weight-semibold {
    font-family: $font-family-semi-bold;
}

.font-weight-regular {
    font-family: $font-family !important;
}