.cursor-pointer {
    cursor: pointer;
}

.cursor-normal {
    cursor: default;
}

.blink-cursor {
    animation: blink 0.8s step-end infinite;
  }
  
  @keyframes blink {
    from, to {
      background-color: transparent;
    }
    50% {
      background-color: $primary;
    }
  }
  