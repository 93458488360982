@import './responsive-variables.scss';

.block{
    display: block !important;
}

.hidden{
    display: none !important;
}

@each $name, $value in $breakpoints {
    @media (min-width: #{$value}) {
        
        .#{$name}\:block{
            display: block !important;
        }

        .#{$name}\:hidden{
            display: none !important;
        }
    }
}