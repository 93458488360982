.overflow-y-auto {
    overflow-y: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.o-hidden {
    overflow: hidden;
}

.o-y-auto {
    overflow-y: auto;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}
