
// Variables
.w-45 {
  width: $spacer * 45;
}

.mx-w-38 {
  max-width: $spacer * 38;
}
// end

[tooltip] {
    display: inline-block;
    
    @extend .w-45;
  }
  .ng-tooltip {
    position: absolute;
    @extend .mx-w-38;
    font-size: $spacer * 4;
    text-align: center;
    color: $white;
    padding: $spacer * 1 $spacer * 2;
    background: $secondary-300;
    border-radius: $spacer * 1;
    z-index: 1000;
    @extend .opacity-none;
  }
  .ng-tooltip:after {
    content: "";
    position: absolute;
    border-style: solid;
  }
  .ng-tooltip-top:after {
    top: 100%;
    left: 50%;
    margin-left: -$spacer * 1 - 1;
    border-width: $spacer * 1 + 1;
    border-color: $secondary-300 transparent transparent transparent;
  }
  .ng-tooltip-bottom:after {
    bottom: 100%;
    left: 50%;
    margin-left: -$spacer * 1 - 1;
    border-width: $spacer * 1 + 1;
    border-color: transparent transparent $secondary-300 transparent;
  }
  .ng-tooltip-left:after {
    top: 50%;
    left: 100%;
    margin-top: -$spacer * 1 - 1;
    border-width: $spacer * 1 + 1;
    border-color: transparent transparent transparent $secondary-300;
  }
  .ng-tooltip-right:after {
    top: 50%;
    right: 100%;
    margin-top: -$spacer * 1 - 1;
    border-width: $spacer * 1 + 1;
    border-color: transparent $secondary-300 transparent transparent;
  }
  .ng-tooltip-show {
    @extend .opacity-fill;
  }

  .tooltip-inner{
    @extend .mmb-button;
    position: relative;
    margin-bottom: $spacer * 3;
  }
  .bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before{
    margin-top: -($spacer * 3);
  }
  
/***********Global Tooltip delay ****************/
.tooltip>div {
  animation-name: delayedFadeIn;
  animation-duration: 1s;
}

@keyframes delayedFadeIn {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
