/*** footer ***/
footer {
    
  .footer-top {
      background-color: $black-200;
      padding: 80px 0;

      .footer-page-link  {
          border-left: 2px solid $investment;
          padding-left: 21px;
          ul{
              list-style: none;

              li {
                  line-height: 30px;
                  a{
                      font-family: 'Open Sans', sans-serif;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 1.71;
                      letter-spacing: normal;
                      color: $page-text-white;
                  }
                  a:hover{
                      color: $investment;
                  }
              }
          }
          
      }
  }
  .footer-bottom {
      padding: 16px 0;
      background-color: $text-black;

      .copy-right {
          display: flex;
          align-items: center;
          .copy-right-logo {
              display: contents;

              img {
                  max-width: 125px;
              }
          }
          a{
              @extend .mmb-caption-2;
              color: $page-text-white;
          }
          a:hover{
              color: $investment;
          }
      }
      .privacy-terms-block {

          ul {
              list-style: none;
              display: flex;
              justify-content: space-between;

              li {
                  display: inline-block;
                  vertical-align: middle;
                  a {
                      @extend .mmb-caption-2;
                      color: $page-text-white;
                  }
                  a:hover{
                      color: $investment;
                  }
              }
          }
      }
      .social-block {
          text-align: right;
          ul {
              list-style: none;
              li {
                  display: inline-block;
                  padding-left: 20px;
                  vertical-align: middle;

                  a {
                      display: block;
                      i {
                        font-size: 20px;
                        display: block;
                        opacity: 0.35;
                        color: $page-text-white;
                      }
                  }
              }
          }
      }
  }
}
@media(max-width:1199px){
  footer {
      .footer-top {
          padding: 50px 0;
      }
  }
}
@media(max-width:991px){
  footer{
      .footer-bottom{
          padding: 12px 0;
          .copy-right {
              justify-content: center;
              margin-bottom: 10px;
          }
          .privacy-terms-block {
              ul {
                  justify-content: flex-start;
                  
                  li {
                      padding-right: 15px;
                  }
              }
          }
      }
  }
}
@media(max-width : 575px){
  footer {
      .footer-top {
          padding: 40px 0 20px 0;
          .footer-page-link {
              padding-left: 20px;
              margin-bottom: 20px;
          }
      }
      .footer-bottom {  
          .copy-right {
              display: block;
              .copy-right-logo {
                  display: block;
                  text-align: center;
              }
          } 
          .privacy-terms-block{
              ul{
                  justify-content: center;
              }
          }
          .social-block {
              text-align: center;
              padding-top: 10px;
               ul {
                   li {
                      padding: 0 10px;
                   }
               }
          }
      }
  }
}
/*** end footer ***/