.project-slider {
	.slick-dots {
		text-align: left;

		li {
			width: auto !important;
			height: auto !important;

			button {
				width: 32px !important;
				height: 12px !important;
				background-color: #D9D9D9 !important;
				border-radius: 20px;
			}

			&.slick-active {
				button {
					width: 12px !important;
					height: 12px !important;
					background-color: #106FFF !important;
				}
			}
		}
	}
}