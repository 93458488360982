.main-verification-block {
    background: url('./../../../projects/mmb-web-app/src/assets/banners/pexels-oliver-sjöström-931018.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.inner-verification {
    border-radius: 6px;
    margin: auto;
    box-shadow: 0 0 18px 0 rgba(185, 190, 202, 0.35);
    background-color: #ffffff;
    text-align: center;
}