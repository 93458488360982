.mmb-overlay {
    @extend .w-100;
    @extend .h-100;
    @extend .mmb-top-0px;
    @extend .mmb-left-0px;
    @extend .position-absolute;
    @extend .opacity-lightest;
    &.mmb-overlay--light {
        background-image: linear-gradient(to bottom, $light-overlay, $black);
    }
}
.card-text-overlay {
  background: linear-gradient(0deg, $black 0%, $light-overlay 100%);
}

.profile-pic-upload-overlay {
  // background: linear-gradient(0deg, $primary 0%, $light-overlay 100%);
  background: $primary;
  opacity: 0;
  &:hover {
    opacity: 0.8;
  }
}