  .clear-search-input:focus+button i.clear-search-icon {
    color: white !important;
  }

  // Step 11
  .transformation-icons {
    height: $spacer*22 !important;
    width: $spacer*22 !important;
  }

  .transformation-icons-description {
    cursor: pointer;

    &:hover {
      div {
        opacity: 1 !important;
      }
    }
  }

  .sustainable-dev-icons {
    height: $spacer*28 !important;
    width: $spacer*28 !important;
  }

  .current-position-roles {
    //&:hover {
    //  @extend .bg-primary, .text-white;
    //}
  }

  .current-position-child {
    //&:hover {
    //  @extend .bg-white, .text-primary;
    //}
  }


  .mmb-onboarding-premium-section {
    background-color: $premium-bg;

    .inner {
      width: 100%;
      height: $spacer * 60;
      background: $light-color;
      padding: $spacer * 9;
      @extend .border-radius-xs;
    }

    .bar {
      background-color: $light-white-bg;
      width: $spacer * 62;
      padding: $spacer * 2;
    }

    .button {
      background-color: $investment;
      width: $spacer * 30;
      padding: $spacer * 4;
    }
  }

  .mmb-onboarding-time {
    background: rgba(255, 255, 255, 0.08);
    // margin-right: $spacer * 20;
  }
