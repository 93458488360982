/**
Mixin file
**/

@mixin after-line ($width, $height, $bgColor, $top, $bottom, $left, $right)  {
    position: absolute;
    content: ""; 
    width: $spacer * 18;
    height: $spacer;
    background-color: $bgColor;
    top: $top;
    bottom: $bottom;
    left: $left;
    right: $right;
    // @each $prop, $value in $extra {
    //     @include tmp-data(add-css($prop, $value));
    // }
}