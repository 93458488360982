@mixin create-margin-utils {
    @for $i from $spacer-range-start through $spacer-range-end {
        .m-#{$i} {
            margin: $spacer * $i !important;
        }
        
        .ml-#{$i} {
            margin-left: $spacer * $i !important;
        }

        .mr-#{$i} {
            margin-right: $spacer * $i !important;;
        }

        .mx-#{$i} {
            margin-left: $spacer * $i !important;
            margin-right: $spacer * $i !important;
        }
        
        .mt-#{$i} {
            margin-top: $spacer * $i !important;
        }

        .mt-n#{$i} {
            margin-top: -$spacer * $i;
        }

        .mb-#{$i} {
            margin-bottom: $spacer * $i !important;
        }

        .my-#{$i} {
            margin-top: $spacer * $i;
            margin-bottom: $spacer * $i;
        }

        .mt-n#{$i} {
            margin-top: - $spacer * $i !important;
        }

        .ml-n#{$i} {
            margin-left: - $spacer * $i !important;
        }
    }
}

.top-0 {
    top: 0;
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

.bottom-0 {
    bottom: 0;
}


@include create-margin-utils;