@import './responsive-variables.scss';

/*
In this file we are creating responsive gaps in order to use them along with grids and flexbox,
like in tailwind css, we use the word gap and then the number of pixels required and it is also reponsive so we can
implement a specific gap in large screens, and in responsive it can be smaller or bigger depending on the requirements
Please note that all increments are in 4px starting from 0px to 100px

for example xl:gap-12px gap-20px will have a gap of 12px in screens larger than 1200px and for smaller screens will have gap of 20px
and so on
*/

@for $i from 0 through 100 {
    .gap-#{$i}px {
      gap: #{($i)}px !important;
    }

    .gap-x-#{$i}px{
        column-gap: #{($i)}px !important;
    }

    .gap-y-#{$i}px{
        row-gap: #{($i)}px !important;
    }

    $i: $i + 4;
}


@each $name, $value in $breakpoints {
    @media (min-width: #{$value}) {
        @for $i from 0 through 100 {

            .#{$name}\:gap-#{$i}px {
                gap: #{($i)}px !important;
              }
          
              .#{$name}\:gap-x-#{$i}px{
                  column-gap: #{($i)}px !important;
              }
          
              .#{$name}\:gap-y-#{$i}px{
                  row-gap: #{($i)}px !important;
              }

              $i: $i + 4;
        }
    }
}