.invite-block{
    background-color: $primary-900;
}

.image-footer{
    background-image: linear-gradient(to bottom, #363c44, #2a2f34);
    // height: 200px;
    .mmb-logo{
        height: 20px;
    }
}
.invite-others-img{
    // height: 700px;
    background-image: url("/assets/banners/Referral@1x-opt.jpeg");
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    min-width: 300px;
    max-width: 100%;
    background-position-x: left;
}

.invite-modal-bg {
    background: $secondary-1000;
    border-radius: 8px;
}
.referral-delete-button {
    &:hover {
        @extend .text-red
    }
     
}