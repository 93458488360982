.top-nav-bar {
    ul {
        li {
            a {
                @extend .text-white;

                &:hover {
                    color: $navigation_hover !important;

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: $primary;
                        border-radius: 2px 2px 0 0;
                    }
                }
            }

            ul {
                visibility: hidden;

                li {
                    a {
                        color: $black-900 !important;
                        width: 100%;

                        &.active {
                            color: $page-text-white !important;

                            .bullet-point {
                                @extend .bg-primary;
                            }
                        }
                    }
                }
            }

            &:hover {
                ul {
                    visibility: visible;

                    li {
                        a {
                            .bullet-point {
                                background-color: transparent;
                            }
                        }

                        a:hover {
                            @extend .text-white;

                            &::before {
                                display: none;
                            }

                            .bullet-point {
                                @extend .bg-primary;
                            }
                        }
                    }
                }
            }
        }
    }
}

.nav-item:hover {

    a,
    i {
        color: $secondary-300 !important;
    }
}

.user-placeholder {
    &:hover {
        @extend .bg-primary
    }
}


@media(max-width:1100px) {
    .right-menu {
        display: none !important;
    }

    .mobile-menu {
        display: flex !important;

        ul {
            li {
                &:hover {
                    background-color: $mobile_nav_hover_bg;

                    .ri-arrow-right-line {
                        color: $page-text-white !important;
                    }
                }
            }
        }
    }
}


.t-navbar-height {
    top: 80px;
}