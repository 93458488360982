.z-index-1 {
    z-index: 1;
}

.z-index-n1 {
    z-index: -1;
}

.z-index-100 {
    z-index: 100;
}

.z-index-200 {
    z-index: 200;
}

.z-index-1000 {
    z-index: 1000;
}