.flat-radio {
    float: left;
    margin: 0 5px 0 0;
    // width: 100px;
    @extend .minw-60px;
    height: 40px;
    position: relative;
    &:hover > label {
      border: 1px solid $buttons-secondary;
    }
  }
  
  .flat-radio label,
  .flat-radio input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .flat-radio input[type="radio"] {
    opacity: 0.011;
    z-index: 100;
  }
  
  .flat-radio input[type="radio"]:checked + label {
    background: $buttons-secondary;
    color: $primary;
  }
  
  .flat-radio label {
    cursor: pointer;
    z-index: 90;
    @extend .border-radius-lg;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary;
    &.active-label{
      border: 1px solid $buttons-secondary;
    }
  }

  .mmb-onboarding-check-option {
    border: 2px solid $onboarding-input-gray;
    &:hover {
      @extend .box-shadow-lg;
      border: 2px solid $black;
    }
  }
