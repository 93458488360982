.border-radius-xs {
  border-radius: $spacer;
}
.border-radius-sm {
  border-radius: $spacer * 2 !important;
}
.border-radius-sm--top {
  border-radius: $spacer * 2 $spacer * 2 0 0 !important;
}
.border-radius-sm--bottom {
  border-radius: 0 0 $spacer * 2 $spacer * 2 !important;
}

.border-radius-md {
  border-radius: $spacer * 6;
}
.border-radius-lg {
  border-radius: $spacer * 20 !important;
}
.border-radius-xl {
  border-radius: $spacer * 25;
}
.border-radius-50 {
  border-radius: 50%;
}
.border-radius-top-left-xs {
  border-top-left-radius: $spacer * 1;
}
.border-radius-top-right-xs {
  border-top-right-radius: $spacer * 1;
}
.border-radius-bottom-left-xs {
  border-bottom-left-radius: $spacer * 1;
}
.border-radius-bottom-right-xs {
  border-bottom-right-radius: $spacer * 1;
}
.border-radius-top-left-sm {
  border-top-left-radius: $spacer * 2;
}
.border-radius-top-right-sm {
  border-top-right-radius: $spacer * 2;
}
.border-radius-bottom-left-sm {
  border-bottom-left-radius: $spacer * 2;
}
.border-radius-bottom-right-sm {
  border-bottom-right-radius: $spacer * 2;
}
.border-radius-bottom-left-md {
  border-bottom-left-radius: $spacer * 8;
}
.border-radius-top-left-md {
  border-top-left-radius: $spacer * 8;
}
.border-radius-top-right-lg {
  border-top-right-radius: $spacer * 12;
}
.border-radius-bottom-left-lg {
  border-bottom-left-radius: $spacer * 12;
}
.border-radius-top-left-lg {
  border-top-left-radius: $spacer * 12;
}
.border-radius-bottom-right-lg {
  border-bottom-right-radius: $spacer * 12;
}
  
.border-radius-12 {
  border-radius: $spacer * 3;
}

.border-radius-10 {
  border-radius: $spacer * 2.5;
}

/**
border-radius
TODO: Remove the below ones
**/