.background-transparent {
  background-color: transparent;
}
.background-no-repeat {
  background-repeat: no-repeat;
}
.background-size-contain {
  background-size: contain;
}
.background-size-cover {
  background-size: cover;
}
.background-size-100 {
  background-size: 100%;
}
.background-position-center {
  background-position: center;
}
.background-position-top {
  background-position: top;
}
.background-attachment{
  background-attachment: fixed;
}