@mixin create-padding-utils {
    @for $i from $spacer-range-start through $spacer-range-end {
        .p-#{$i} {
            padding: $spacer * $i !important;
        }
        
        .pl-#{$i} {
            padding-left: $spacer * $i !important;
        }

        .pr-#{$i} {
            padding-right: $spacer * $i !important;
        }

        .px-#{$i} {
            padding-left: $spacer * $i !important;
            padding-right: $spacer * $i !important;
        }
        
        .pt-#{$i} {
            padding-top: $spacer * $i !important;
        }

        .pb-#{$i} {
            padding-bottom: $spacer * $i !important;
        }

        .py-#{$i} {
            padding-top: $spacer * $i !important;
            padding-bottom: $spacer * $i !important;
        }
    }
}

@include create-padding-utils;