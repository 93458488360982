.contact-form-block {
    .contact-form-inner {
        .contact-form-left {
            .form-group {
                input:focus {
                    background-color: $page-text-page-background !important;
                    box-shadow: 0 6px 12px 0 $light-gray !important;
                }

                input::placeholder {
                    color: $page-text-grey !important;
                }

                select {
                    background-image: url('./../../../projects/mmb-web-app/src/assets/images/arrow-down-s-line.png');
                    background-repeat: no-repeat;
                    background-position: right 15px top 50%, 0 0;
                }

                select:focus {
                    background-color: $page-text-page-background !important;
                    box-shadow: 0 6px 12px 0 $light-gray !important;
                }

                textarea:focus {
                    border: solid 1px $buttons-primary !important;
                    box-shadow: 0 6px 12px 0 $light-gray !important;
                }
            }

            button.send:hover {
                background-color: $buttons-primary;
                color: $page-text-white;
                border: solid 1px $buttons-primary;
            }
        }

        .contact-form-right {
            .contact-form-bottom {
                background-image: linear-gradient(to bottom, #363c44, #2a2f34);
            }
        }
    }
}