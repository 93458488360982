.pulse-search {
  .pulse-search-right {
      form {
          .input-Search{
              input::placeholder {
                  color: $page-text-white;
                  letter-spacing: 0.5px;
              }
              i{
                  transform: translate(0, -50%);
              }
          }
      }
  }
}
.pulse-tab-block {
  .pulse-tabs {
      li{
          a.nav-link:hover,
          a.nav-link.active {
              color: $secondary-300;
              border-bottom: 3px solid $secondary-300 !important;
              i {
                  color: $secondary-300;
              }
          }
      }
  }
  .pulse-tabs-content-block {
    background-image: url(/assets/images/pulse/pules-tab-bg.jpg);
     .pulse-tabs-content-inner {
          .pulse-tabs-text {
              a:hover {
                  background-color: $page-text-white;
                  color: $text-black !important;
              }
          }
      }
  }
}
.pulse-subscribe-block {
  .pulse-subscribe-button {
      a:hover {
          background-color: $page-text-white !important;
          color: $secondary-300 !important;
      }
  }
}
.pulse-card {
	.pulse-inner-card {
		.vertical-card:hover {
			box-shadow: 0 0 24px 0 rgba(185, 190, 202, 0.25), 0 4px 8px 0 rgba(185, 190, 202, 0.15);
		}
	}
}
