.mmb-progrees-bar {
    width: 100%;
    height: 16px;
    background-color: $bg-light;
    @extend .border-radius-md;
    overflow: hidden;
    .liner {
        height: 16px;
        background-color: $btn-color--secondary;
        &.green {
            background-color: $notification-affirmative;
        }
    }
}