@import './responsive-variables.scss';

/*
here we implemented the flexbox reponsive classes, as the rest of the responsive structure, we have the responsive map variable, where we have 
different breakpoints, we can extend these breakpoints and the loop will update the classes accordingly,

for example md:flex-row flex-col will have the flex direction as row for screens larger than 576px and flex direction as column for
smaller screens
*/

.flex{
    display: flex !important;
}

.grow{
    flex-grow: 1 !important;
}

.shrink{
    flex-shrink: 0 !important;
}

.flex-col{
    flex-direction: column !important;
}

.flex-row{
    flex-direction: row !important;
}

.justify-center{
    justify-content: center !important;
}

.justify-start{
    justify-content: start !important;
}

.justify-end{
    justify-content: end !important;
}

.items-start{
    align-items: start !important;
}

.items-center{
    align-items: center !important;
}

.items-end{
    align-items: end !important;
}

.justify-between{
    justify-content: space-between !important;
}



@each $name, $value in $breakpoints {
    @media (min-width: #{$value}) {
        .#{$name}\:flex{
            display: flex !important;
        }

        .#{$name}\:grow{
            flex-grow: 1 !important;
        }
        
        .#{$name}\:shrink{
            flex-shrink: 0 !important;
        }
        
        .#{$name}\:flex-col{
            flex-direction: column !important;
        }
        
        .#{$name}\:flex-row{
            flex-direction: row !important;
        }
        
        .#{$name}\:justify-center{
            justify-content: center !important;
        }

        .#{$name}\:justify-between{
            justify-content: space-between !important;
        }
        
        
        .#{$name}\:justify-start{
            justify-content: start !important;
        }
        
        .#{$name}\:justify-end{
            justify-content: end !important;
        }
        
        .#{$name}\:items-start{
            align-items: start !important;
        }
        
        .#{$name}\:items-center{
            align-items: center !important;
        }
        
        .#{$name}\:items-end{
            align-items: end !important;
        }
    }
}