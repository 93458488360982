/**
Box shadow utilities
*/
.box-shadow-xs {
    box-shadow: 0 0 $spacer * 0.5 0 $shadow-color;
}
.box-shadow-sm {
    box-shadow: 0 0 $spacer * 1 0 $shadow-color;
}
.box-shadow-md {
    box-shadow: 0 0 $spacer * 2 0 $shadow-color;
}
.box-shadow-lg {
    box-shadow: 0 0 $spacer * 5 0 $shadow-color;
}
.box-shadow-xl {
    box-shadow: 0 0 $spacer * 6 0 $shadow-color;
}
.box-shadow-none {
    box-shadow: none !important;
}

.button-box-shadow {
    box-shadow: 0 4px 6px 0 $light-gray;
}

.button-box-shadow-sm {
    box-shadow: 0 2px 2px 0 $light-gray;
}

.testimonails-box-shadow {
    box-shadow: 0px 4px 56px 0px rgba(0, 0, 0, 0.08);
}

.membership-section-shadow {
    box-shadow: 0px 0px 48px 0px rgba(0, 0, 0, 0.08);
}

