@import './responsive-variables';

/*
In this section I extended the already implemented utilities class for margins, with the same behavior but I just added the responsive
prefix in case we need them responsive (same spacer range start and end)
*/

@each $name, $value in $breakpoints {
    @media (min-width: #{$value}) {
        @for $i from $spacer-range-start through $spacer-range-end {
            .#{$name}\:m-#{$i} {
                margin: $spacer * $i !important;
            }
            
            .#{$name}\:ml-#{$i} {
                margin-left: $spacer * $i !important;
            }
        
            .#{$name}\:mr-#{$i} {
                margin-right: $spacer * $i !important;;
            }
        
            .#{$name}\:mx-#{$i} {
                margin-left: $spacer * $i !important;
                margin-right: $spacer * $i !important;
            }
            
            .#{$name}\:mt-#{$i} {
                margin-top: $spacer * $i !important;
            }
        
            .#{$name}\:mt-n#{$i} {
                margin-top: -$spacer * $i;
            }
        
            .#{$name}\:mb-#{$i} {
                margin-bottom: $spacer * $i !important;
            }
        
            .#{$name}\:my-#{$i} {
                margin-top: $spacer * $i;
                margin-bottom: $spacer * $i;
            }
        
            .#{$name}\:mt-n#{$i} {
                margin-top: - $spacer * $i !important;
            }
        
            .#{$name}\:ml-n#{$i} {
                margin-left: - $spacer * $i !important;
            }
        }
    }
}