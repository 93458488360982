.custom-counter {
    list-style-type: none;
    margin: 0;
  }
  .custom-counter li {
    counter-increment: step-counter;
    margin-bottom: 5px;
    list-style-position: inside;
    @extend .pl-10;
    position: relative;
  }
  .custom-counter li::before {
    content: counter(step-counter);
    margin-right: 20px;
    @extend .mmb-caption-2;
    background-color: $bright-green;
    color: white;
    width: $spacer * 7;
    height: $spacer * 7;
    border-radius: 50%;
    left: 0;
    position: absolute;
    top: 0;
    @extend .mmb-display-flex;
    @extend .mmb-justify-content-center;
    @extend .mmb-align-items-center;
  }