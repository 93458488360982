.mmb-icon-xs {
    font-size: $spacer * 2.5;
}

.mmb-icon-sm {
    font-size: $spacer * 3;
}

.mmb-icon-md {
    font-size: $spacer * 4;
    width: $spacer * 12;
}

.mmb-icon-lg {
    font-size: $spacer * 5;
}

.mmb-icon-xl {
    font-size: $spacer * 6;
}

.mmb-logo-xs {
    max-height: $spacer * 5;
}

.mmb-logo-sm {
    max-height: $spacer * 8;
}

.mmb-icon i {
    @extend .mmb-title,
    .text-white,
    .opacity-lightest;
}

.mmb-icon-container:hover i {
    @extend .opacity-fill,
    .box-shadow-md;
}