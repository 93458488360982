.text-verticle {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(-180deg);
  transform-origin: bottom left;
  height: 200px;
  width: 40px;
}

.verticle-txt-1 {
  margin-top: -70px;
  margin-left: 100px;
  text-shadow: 1px 1px 11px rgba(18, 3, 33, 0.89);
}

.horizontal-txt-2 {
  margin-top: -20px;
  margin-left: 170px;
  text-align: left;
  text-shadow: 1px 1px 11px rgba(18, 3, 33, 0.89);
}

.verticle-txt-3 {
  margin-top: -150px;
  margin-left: 220px;
  text-shadow: 1px 1px 11px rgba(18, 3, 33, 0.89);
}


.problem-box-about-us {
  width: 100%;
  height: $spacer * 44;
  display: flex;
  align-items: center;
  justify-content: start;
  @extend .border-radius-md;

  .number-circle {
    width: $spacer * 16;
    height: $spacer * 16;
    @extend .border-radius-lg;
  }
}

.number-circle-md {
  width: $spacer * 10;
  height: $spacer * 10;
  @extend .border-radius-lg;
}