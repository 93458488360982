/**
== 
Widths
==
.width-{} ==> width 
.mx-{} ==> max-width
.mi-{} ==> min-width

p suffix is used for percent
**/

@mixin create-width-utils {
    @for $i from $spacer-range-start through $spacer-range-end * 15 {
        // TODO: Add a if to only create below classes when multiple of $spacer
        .w-#{$i}px {
            width: #{$i}px !important;
        }

        .minw-#{$i}px {
            min-width: #{$i}px !important;
        }

        .maxw-#{$i}px {
            max-width: #{$i}px !important;
        }
    }
}

@include create-width-utils;

.w-100p,
.w-100 {
    width: 100% !important;
}

.w-24px {
    width: 24px;
}

.w-28px {
    width: 28px;
}
.w-30px {
    width: 30px;
}
.w-50px {
    width: 50px;
}

.w-64px {
    width: 64px;
}
.w-72px {
    width: $spacer * 18 !important;
}
.w-80px {
    width: 80px;
}
.w-88px {
    width: 88px;
}

.w-100px{
    width: 100px;
}

.w-120px{
    width: 120px !important;
}

.w-128px{
    width: 128px !important;
}
.w-130px{
    width: 130px !important;
}

.w-160px{
    width: $spacer * 40;
}

.w-200px{
    width: $spacer * 50;
}

.w-250px {
    width: 250px;
}
.w-300px {
    width: 300px;
}

.w-30 {
    width: 30%;
}

.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}


.w-65 {
    width: 65%;
}

.w-70 {
    width: 70%;
}

.w-75p {
    width: 75%;
}
.w-85pct {
    width: 85%;
}
.w-85p {
    max-width: 85%;
}
.w-95 {
    width: 95%;
}

.mw-30{
    min-width: 30%;
}

.w-50p,
.w-50 {
    width: 50% !important;
}

.w-40{
    width: 40%;
}

.mx-w-100,
.mx-w-100p {
    max-width: 100% !important;
}
.mx-w-40p{
max-width: 40% !important;
}
.maxw-50,
.mx-w-50{
    max-width: $spacer * 50;
}

.minw-50 {
    min-width: $spacer * 50;
}
.minw-60p {
    min-width: 60% !important;
}

.minw-auto {
    min-width: auto !important;
}

.minw-40 {
    min-width: $spacer * 40;
}

.mx-w-80px {
    max-width: 80px;
}
.mx-w-120px {
    max-width: 120px;
}

.mx-w-80 {
    max-width: 80% !important;
}

.minw-300px {
    min-width: 300px;
}
.minw-268px {
    min-width: 268px;
}

.minw-350px {
    min-width: 350px;
}

.minw-400px {
    min-width: 400px !important;
}

.maxw-300px {
    max-width: 300px !important;
}
.maxw-400px {
    max-width: 400px !important;
}
.maxw-275px {
    max-width: 275px !important;
}

.maxw-900px {
    max-width: 900px;
}

.w-500px {
    width: 500px;
}
.mx-w-450px {
    max-width: 450px !important;
}
.mx-w-480px {
    max-width: 480px !important;
}
.mx-w-500px {
    max-width: 500px !important;
}

.w-600px {
    width: 600px;
}
.w-800px {
    width: 800px;
}
.w-900px {
    width: 900px;
}
.mx-w-600px {
    max-width: 600px;
}
.mx-w-700px {
    max-width: 700px;
}
.mx-w-800px {
    max-width: 800px;
}

.mx-w-900px {
    max-width: 900px;
}

.mx-w-1100px {
    max-width: 1100px;
}

.w-auto {
    width: auto !important;
}
.w-fit-content {
    width: fit-content !important;
}

.maxw-100vw {
    max-width: 100vw;
}

.maxw-100 {
    max-width: 100%;
}