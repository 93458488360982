.line {
    &.has-bottom-line::after {            
        @include after-line($spacer * 18, $spacer, $secondary-300, 'unset', 0, 0, 'unset');
    }

    &.has-top-line {         
        &::after {
            @include after-line($spacer * 27, $spacer, $secondary-300, 0, 'unset', 0, 'unset');
        }   

        &.has-top-line--lightest::after {
            @include after-line($spacer * 27, $spacer, $white, 0, 'unset', 0, 'unset');
        }
        &.has-top-line--highlight::after {
            @include after-line($spacer * 27, $spacer, $bg-yellow, 0, 'unset', 0, 'unset');
        }
    }
}