// Logo image animation
// Toggles the display of logo with title
.mmb-animate-logo-icon-hide {
    transform: scale(0);
    opacity: 0 !important;
    transition: all 0.5s ease-in-out;
}

img.mmb-animate-logo-icon-show {
    max-height: 88px;
    &.xs-logo {
        max-height: 70px;
    }
}

.mmb-animate-logo-icon-show {
    transform: scale(1);
    opacity: 1 !important;
    transition: all 0.5s ease-in-out;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    70% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
     }
}

  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
              opacity: 0.3;
    }
    100% {
      -webkit-transform: translateY(-25px);
              transform: translateY(-25px);
              opacity: 1;
    }
  }

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.ri-loader-line {
  animation: spin 2s linear infinite;
}
