.ngx-slider-bubble.ngx-slider-model-value,
.ngx-slider-bubble.ngx-slider-limit,
.ngx-slider-bubble.ngx-slider-model-high {
    display: none !important;
}

.ngx-slider .ngx-slider-pointer:after {
    content: '||' !important;
    width: 0 !important;
    height: 0 !important;
    background-color: none !important;
    color: $white !important;
    top: 7px !important;
    left: 9.5px !important;
    font-size: 12px !important;
}

.ngx-slider .ngx-slider-pointer,
.ngx-slider .ngx-slider-selection {
    @extend .bg-primary;
}

.ngx-slider.animate .ngx-slider-pointer {
    &:focus-visible {
        border: none !important;
        outline: none !important;
    }

    &:active {
        border: none !important;
        outline: none !important;
    }
}

.ngx-slider .ngx-slider-bar {
    background: none !important;
    border: 1px solid $white !important;
}

.ngx-slider .ngx-slider-selection {
    border: 1px solid $primary !important;
    background-color: $primary !important;

}

.ngx-slider .ngx-slider-bubble {
    color: $page-text-white !important;
}

.slick-slider {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.slick-list.draggable {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}