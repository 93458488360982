.transform-y-50 {
  transform: translateY(-50%);
}

.rotate-180 {
  transform: rotate(180deg);
}

.text-align--center {
  text-align: center !important;
}
.text-align-left {
  text-align: left !important;
}

.word-break {
  word-break: break-word;
}

.white-space-nowrap {
  white-space: nowrap;
}

.no-overlap {
  white-space: normal;
}

.word-wrap--break-word {
  word-wrap: break-word;
}

.word-break--break-all {
  word-break: break-all;
}

.text-transform-none {
  text-transform: none;
}

.shadow-none {
  box-shadow: none;
}

.v-align--middle {
  vertical-align: middle;
}

.font-italic {
  font-style: italic;
}

.font-normal {
  font-style: normal !important;
}

.shape-rounded {
  border-radius: 50% !important;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
}
