.switch {
    display: flex;
    align-items: center;
    margin: 0 10px;
  }
  .switch input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  .switch input[type=checkbox]:checked + label {
    background: $secondary-300;
  }
  .switch input[type=checkbox]:checked + label::after {
    left: calc(100% - 4px);
    transform: translateX(-100%);
  }
  .switch label {
    cursor: pointer;
    width: $spacer * 9;
    height: $spacer * 6;
    background: $black-600;
    display: block;
    border-radius: 24px;
    position: relative;
  }
  .switch label::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    background: $white;
    border-radius: 16px;
    transition: 0.3s;
  }