/**
===========================
Core layer to set the theme
===========================
*/
@import './variables';
@import './core/mixin';

@import './typography';
@import './core/core';

// TODO: We may want to remove mixin later as it should be in dedicatd file e.g. buttons mixin should be in buttons file
@import './mixin';

/**
==========
Components
==========
*/
@import './components/components';

/**
=========
Utils & overrides
=========
*/
@import './utilities/utilities';

@import './core/responsive-media-queries/responsive-media-queries';

// TODO: We need to remove common and move styles to utils (and to core)
@import './common';
