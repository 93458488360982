.mmb-persona-card {
    min-height: $spacer * 33;
    border: $spacer * 0.5 solid $white;
    &:hover {
        border: $spacer * 0.5 solid $primary;
        @extend .box-shadow-md;
        .desc {
            color: $primary !important;
        }
    }
    .desc-wrap{
        @extend .mmb-border--dark; 
    }
}

.active-card {
    min-height: 100%;
    border: $spacer * 0.5 solid $buttons-primary;
    @extend .border-radius-sm;
    .title-wrap {
        background-color: $buttons-primary !important;
    }
    &:hover {
        .desc {
            color: $white !important;
            @extend .mmb-border-none;
        }
    }
    // .desc-wrap{
    //     @extend .mmb-border-none; 
    // }
    // &.fill {
    //     min-height: $spacer * 27;
    //     max-height: $spacer * 27;
    //     border: $spacer * 0.5 solid $buttons-secondary;
    //     background-color: $buttons-secondary;
    //     &:hover {
    //         color: $buttons-secondary !important;
    //     }
    // }
}