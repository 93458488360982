.mmb-letter-spacing-normal {
    letter-spacing: normal;
}
.mmb-letter-spacing-xs {
    letter-spacing: 0.05rem;
}

.mmb-letter-spacing-sm {
    letter-spacing: 0.10rem;
}

.mmb-letter-spacing-md {
    letter-spacing: 1px;
}

.mmb-letter-spacing-ng-sm {
    letter-spacing: -1.75px;
}