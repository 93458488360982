.mmb-rounded-circle {
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    object-fit: cover;
    border-radius: 50%;

    &.sm {
        width: 18px;
        min-width: 18px;
        max-width: 18px;

        height: 18px;
        min-height: 18px;
        max-height: 18px;
    }

    &.sm-plus {
        width: 24px;
        min-width: 24px;
        max-width: 24px;

        height: 24px;
        min-height: 24px;
        max-height: 24px;
    }

    &.regular {
        width: 32px;
        min-width: 32px;
        max-width: 32px;

        height: 32px;
        min-height: 32px;
        max-height: 32px;
    }

    &.md {
        width: $spacer * 14;
        height: $spacer * 14;
        min-width: $spacer * 14;
        min-height: $spacer * 14;
    }

    &.lg {
        width: $spacer * 18;
        height: $spacer * 18;
        min-width: $spacer * 18;
        min-height: $spacer * 18;
    }

    &.xl {
        width: $spacer * 24;
        height: $spacer * 24;
        min-width: $spacer * 24;
        min-height: $spacer * 24;
    }
}

.mmb-img--md {
    width: $spacer * 8;
}

.mmb-img--lg {
    width: $spacer * 41;
}

.img-filter--grayscale {
    filter: grayscale(100%);
}


.object-fit-cover {
    object-fit: cover;
}

.object-fit-contain {
    object-fit: contain !important;
}

.logo-icon {
    width: 24px;
}

.material-icon-circle-xs {
    background: $primary;
    color: $white;
    border-radius: 50%;
    font-size: 12px;
    width: 16px;
    height: 16px;
    padding: 2px;
}

.mmb-image-tile {
    &:hover {
        opacity: 1;
    }
}

.fp-xl {
    line-height: 125px;
    width: 100%;
}

.mmb-icon-lg {
    font-size: $spacer * 18;
}

.mmb-icon-md-2 {
    font-size: $spacer * 8 !important;
}

.mmb-icon-md {
    font-size: $spacer * 10 !important;
}

.mmb-background {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-size: cover !important;

    &.login-page-bg {
        background: url("./../../../projects/mmb-web-app/src/assets/banners/pexels-oliver-sjöström-931018.jpg");
    }

    &.interest-confirmation-bg {
        background: url("./../../../projects/mmb-web-app/src/assets/banners/interest-confirmation-bg-image-2.jpeg");
    }

    &.register-page-bg {
        background: url("./../../../projects/mmb-web-app/src/assets/banners/pexels-pixabay-315998.jpg");
    }

    &.onboarding-set-password-bg {
        background: url("./../../../projects/mmb-web-app/src/assets/banners/pexels-oliver-sjöström-931018.jpg");
    }

    &.onboarding-welcome-bg {
        background: url("./../../../projects/mmb-web-app/src/assets/banners/pexels-pixabay-315998.jpg");
    }
}

.hide-img-on-mobile {
    display: none !important;
}