a {
    cursor: pointer;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    color: $primary;
}

a.disabled {
    pointer-events: none;
}

.underline--hover {
    &:hover {
        text-decoration: underline;
    }
}

.link--hover:hover {
    cursor: pointer;
    border-bottom: 1px solid $primary;
    color: $primary !important;
}

.link--pointer:hover {
    cursor: pointer;
}

[role="link"] {
    cursor: pointer;

    &:hover {
        transform: scale(1.25);
        transition: all 0.2s;
    }
}

.dark-link {
    border-bottom: $spacer * 0.25 solid $white;

    &:hover {
        @extend .text-secondary-300;
        border-bottom: $spacer * 0.25 solid $secondary-300;
    }
}