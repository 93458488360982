/*
Patch for issue in ng-bootstrap for typeahead - https://github.com/ng-bootstrap/ng-bootstrap/issues/2457
*/
.visually-hidden {
  display: none !important;
}

/* End of patch */

.form-control:disabled,
.form-control[readonly] {
  background-color: inherit;
}

input {
  outline: none;
}

.mmb-input-group-autocomplete:not(.mmb-input-group--disabled).is-invalid,
input.ng-invalid.ng-touched:not(.mmb-input-outline-none),
textarea.ng-invalid.ng-touched:not(.mmb-input-outline-none) {
  border: 1px solid $notification-error;
}

.invalid-field{
  border: 1px solid $notification-error;
}

textarea.bg-secondary-500 {

  &:active,
  &:focus {
    @extend .bg-secondary-500;
  }
}

.mmb-form-group {
  @extend .w-100;
  margin-bottom: $spacer * 3;

  .mmb-form-control {
    @extend .w-100;
    @extend .border-radius-xs;
    height: $spacer * 12;
    border: 1px solid $other;
    padding: 0 ($spacer * 4);

    &:focus {
      border: 1px solid $secondary-300;
      @extend .bg-white;
      @extend .box-shadow-lg;
      @extend .mmb-outline-none;
    }
  }

  .mmb-textarea {
    @extend .w-100;
    @extend .border-radius-xs;
    border: 1px solid $other;
    padding: ($spacer * 3) ($spacer * 4);
    height: $spacer * 24;
    resize: none;

    &:focus {
      border: 1px solid $secondary-300;
      @extend .bg-white;
      @extend .box-shadow-lg;
      @extend .mmb-outline-none;
    }
  }

  &:focus {
    label {
      border: 1px solid $secondary-300;
      @extend .bg-white;
      @extend .box-shadow-lg;
    }
  }
}

.mmb-form-control--rounded {
  @extend .w-100;
  @extend .border-radius-lg;
  max-width: $spacer * 106;
  height: $spacer * 12;
  border: 1px solid $white;
  background: transparent;
  color: $white-opacity-xs;

  &::placeholder {
    color: $white-opacity-xs;
  }
}

.mmb-comment--focus {
  border: 1px solid $secondary-300 !important;
  @extend .box-shadow-lg;

  .form-control {
    &:focus {
      @extend .mmb-outline-none;
      @extend .box-shadow-none;
    }
  }
}

.mmb-select-filter {
  width: 100%;
  height: $spacer * 12;
  @extend .border-radius-xs;
  @extend .mmb-border-white-sm;
  @extend .box-shadow-none;
  @extend .mmb-caption-2;

  &:hover {
    @extend .mmb-border-white-sm;
  }

  &:focus {
    border: 1px solid $secondary-300 !important;
    @extend .bg-white;
    @extend .box-shadow-lg;
    @extend .mmb-outline-none;
  }
}

.mmb-form-control-onboarding {
  label {
    @extend .mmb-caption-2, .mb-3;
    color: $white;
  }

  .mmb-form-control {
    @extend .mmb-caption-2;
    @extend .w-100;
    @extend .border-radius-xs;
    height: $spacer * 14;
    border: 1px solid $white;
    padding: 0 ($spacer * 4);

    &:focus {
      border: 1px solid $white;
      background-color: transparent;
      color: $white;
      @extend .mmb-outline-none;

      &::placeholder {
        color: $white;
      }
    }

    &.text-area {
      height: $spacer * 30;
    }

    &input[type="date"]::-webkit-calendar-picker-indicator {
      width: 500px;
    }
  }

  input[type="date"]:focus::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  .mmb-form-control-group {
    @extend .w-100;
    @extend .border-radius-xs;
    @extend .bg-white;
    height: $spacer * 14;
    border: 1px solid $other;
    padding: 0 ($spacer * 4);
  }

  ngb-typeahead-window.dropdown-menu.show {
    width: 300px;
    margin-left: 0;
  }

  .input-group--disabled {
    pointer-events: none;
    background-color: $dissable-input-onboarding !important;
    color: $dissabled-textbox !important;
    border: none !important;

    &::placeholder {
      color: $dissabled-textbox !important;
    }
  }

  .dropdown-menu {
    margin-left: -50px;
  }

  .wrapper-control {
    @extend .w-100;
    @extend .border-radius-xs;
    @extend .bg-white;
    height: $spacer * 14;
    border: 1px solid $other;
    padding: 0 ($spacer * 4);

    input {
      background-color: transparent !important;
      background: none !important;
    }

    &.focus {
      border: 1px solid $white !important;
      background-color: transparent !important;
      color: $white !important;
      @extend .mmb-outline-none;

      input {
        color: $white !important;

        &::placeholder {
          color: $white !important;
        }
      }
    }
  }
}

.flat-radio {
  &:hover > label {
    @extend .bg-secondary--light;
    @extend .text-primary;
  }
}


.mmb-form-group textarea.mmb-form-control {
  height: auto;
}

textarea.form-control.auto-height-text {
  padding: 6px 8px !important;
  resize: none !important;
  height: 35px;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.filled-input {
  border: 1px solid $white !important;
  background-color: $transparent !important;
  color: $white !important;
}

.filled-black {
  border: 1px solid $white !important;
  background-color: $transparent !important;
  color: $black !important;
}

.onboarding-expand-wrap .mat-expansion-panel-body {
  padding: 0 0 !important;
}

/*******************Hide Drag border on image upload************************/
.ngx-file-drop__drop-zone {
  @extend .mmb-border-none;
  @extend .border-radius-sm;
  height: 400px;
}

.ngx-file-drop__drop-zone--over {
  background: none !important;
}

/*
    Onboarding 2.0 styles below
*/
.mmb-form-control-onboarding2 {
  @extend .mb-3;
  .mmb-form-control {
    @extend .mmb-caption-2;
    @extend .w-100;
    @extend .border-radius-xs;
    border: 2px solid $onboarding-input-gray;
    padding: 0 ($spacer * 4);

    &:not(textarea) {
      height: $spacer * 14;
    }

    // if input is filled
    &:not(:placeholder-shown) {
      background-color: $onboarding-input-bg-gray !important;
      color: $text-black;
      @extend .mmb-outline-none;
    }

    // if input is focused
    &:focus {
      border: 2px solid $primary;
      background-color: $white;
      color: $text-black;
      @extend .mmb-outline-none;
    }

    // placeholder
    &::placeholder {
      color: $text-black;
    }

    &.text-area {
      height: $spacer * 60;
    }

    & input[formcontrolname="countryRegion"] {
      background-color: $transparent !important;
    }

    &.input-group--disabled {
      pointer-events: none;
      color: rgba(17, 17, 17, 0.16) !important;
      border: 1px solid rgba(17, 17, 17, 0.16) !important;
  
      &::placeholder {
        color: rgba(17, 17, 17, 0.16) !important;
      }
      
      input {
        background-color: transparent !important;
      }
    }

  }

  & input[formcontrolname="inviteCode"] {
    @extend .maxw-150px;
    @extend .border-radius-lg;
    border: 2px solid $text-black;
    background: $white-bg-fff;
    text-align: center;

    &:focus {
      border: 2px solid $text-black;
    }

    // placeholder
    &::placeholder {
      text-align: center;
    }
  }

  .flat-radio-onboarding label {
    @extend .border-radius-lg;
    @extend .mmb-caption-2;
    @extend .w-100;
    @extend .text-black;
    height: $spacer * 14;
    border: 2px solid $onboarding-input-gray;
    padding: 0 ($spacer * 4);
    &.active-label{
      @extend .bg-primary;
      @extend .text-white;
    }
  }

}

/*
End of Onboarding 2.0 styles
*/
