body {
    @extend .bg-light;
    @extend .mmb-caption-2;
}

/**
Safari fix - hide autocomplete button
**/
input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}

/** common **/
.common-title.white{
    h6 {
        color: $page-text-white;
    }
    h2 {
        color: $page-text-white;
    }
}
.common-title.two{
    h6 {
        margin-bottom: 24px;
    }
}
.common-title {
    text-align: center;
    max-width: 90%;
    margin: 0 auto;
    i {
        color: $investment;
        font-size: 70px;
        display: block;
        margin-bottom: 50px;
    }
    h6 {
        font-family: 'Helvetica Now Display';
        line-height: 1.33;
        letter-spacing: 1px;
        text-align: center;
        color: $text-black;
        margin-bottom: 16px;
    }
    h2 {
        font-family: 'Helvetica Now Display';
        font-size: 56px;
        font-weight: bold;
        line-height: 1.14;
        letter-spacing: -1px;
        text-align: center;
        color: $text-black;
        margin-bottom: 48px;
    }
    a{
        background-color: $investment;
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        color: $page-text-white;
        padding: 9px 20px 10px 20px;
        border-radius: 100px;
    }
    a:hover{
        background-color: $buttons-primary;
    }
}

.transparent-modal{
    .modal-content{
        background: transparent !important;
    }

}
/** end common **/
/***/
.slick-slide{
    outline: none;
}
/***/
/** owl-dot */
.join-talent {
    .join-talent-inner {
        .slick-dotted.slick-slider {
            margin-bottom: 0;
        }
        .slick-dots {
            position: inherit;
            bottom: 0;
            margin-top: 60px;
    
            li {
                height: auto;
                width: auto;
                margin: 0 3px;
    
                button {
                    padding: 0;
                    width: 12px;
                    height: 12px;
                    border: 1px solid $text-black;
                    margin: 0;
                    border-radius: 100px;
                }
                button:before {
                    display: none;
                }
            }
            li.slick-active {
    
                button {
                    background-color: $text-black;
                    border: 1px solid $text-black;
                }
            } 
        }
    }
}
/** end owl-dot */
/** slick **/
.collaboration-block {
    .slick-dotted.slick-slider {
        margin-bottom: 0;
    }
    .slick-dots {
        position: inherit;
        bottom: 0;
        margin-top: 60px;

        li {
            height: auto;
            width: auto;
            margin: 0 3px;

            button {
                padding: 0;
                width: 12px;
                height: 12px;
                border: 1px solid $page-text-white;
                margin: 0;
                border-radius: 100px;
            }
            button:before {
                display: none;
            }
        }
        li.slick-active {

            button {
                background-color: $investment;
                border: 1px solid $investment;
            }
        } 
    }
}
/** end slick **/
@media(max-width:1199px){
    .common-title {
        i {
            font-size: 50px;
            margin-bottom: 20px;
        }
        h6 {
            font-size: 22px;
            margin-bottom: 15px;
        }
        h2 {
            font-size: 40px;
            margin-bottom: 35px;
        }
    }
    .common-title.two{
        h6 {
            margin-bottom: 20px;
        }
    }
    .collaboration-block {
        .slick-dots {
            margin-top: 40px;
        }
    }
    .join-talent {
        .join-talent-inner {    
            .slick-dots {
                margin-top: 40px;
            }
        }
    }
    .slider-logo-mobile {
        .owl-stage {
            display: flex;
            align-items: center;
        }
    }
}
@media(max-width:991px){
    .common-title {
        h6 {
            font-size: 20px;
            margin-bottom: 10px;
        }
        h2 {
            font-size: 30px;
            font-weight: 500;
            letter-spacing: 0;
            margin-bottom: 25px;

        }
    }
    .common-title.two {
        h6 {
            margin-bottom: 10px;
        }
    }  
    .join-talent {
        .join-talent-inner {    
            .slick-dots {
                margin-top: 30px;
            }
        }
    }
    .collaboration-block {
        .slick-dots {
            margin-top: 20px;
        }
    }
}
@media(max-width:767px){
    .container {
        max-width: 100%;
    }
}
@media(max-width:575px){
    .common-title {
        max-width: 100%;
        h6 {
            font-size: 18px;
        }
        h2 {
            font-size: 24px;
        }
    } 
}