.mmb-cms {
    strong {
        @extend .mmb-body-2;
        font-weight: bold;
    }

    p, ol, ul, li, span, label {
        @extend .mmb-body-1;
    }

    h1 {
        @extend .mmb-display-1;
    }

    h2 {
        @extend .mmb-headline;
    }
    
    h3 {
        @extend .mmb-subheading-1;
    }

    a { 
        @extend .mmb-body-1;
    }

    ol, ul {
        padding: 0 $spacer * 5;
    }
}